import { useEffect, useState } from 'react';

type HashCallback = (currentHash: string) => void;

const useHash = (hashString: string, callback?: HashCallback) => {
  const [hash, setHash] = useState<string>('');

  useEffect(() => {
    const handleHashChange = () => {
      const currentHash = window?.location.hash;
      if (currentHash !== hashString) {
        return;
      }
      setHash(currentHash);
      if (callback) {
        callback(currentHash);
      }
    };

    // Set initial hash
    handleHashChange();

    // Add event listener for hash change
    window?.addEventListener('hashchange', handleHashChange);

    // Clean up the event listener
    return () => {
      window?.removeEventListener('hashchange', handleHashChange);
    };
  }, []);

  return hash;
};

export default useHash;

